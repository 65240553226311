<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portPricing.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr v-show="portDetails && portDetails.portDocuments && portDetails.portMainData.hinnakiri != ''">
                    <th scope="row">{{ $t('portPricing.pricingList') }}</th>
                    <td>
                        <a
                            :href="portDetails.portMainData.hinnakiri.startsWith('http') ? portDetails.portMainData.hinnakiri : 'http://'+portDetails.portMainData.hinnakiri"
                            target="_blank"
                        >{{ portDetails && portDetails.portMainData ? portDetails.portMainData.hinnakiri : '' }}</a
                        >
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "PortPricing",
    props: ["portDetails"]
};
</script>

<style scoped></style>
