<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.quays.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr class="jaotus">
                    <th scope="row">{{ $t('portDetails.quays.name') }}</th>
                    <td>{{ $t('portDetails.quays.class') }}</td>
                    <td>
                        {{ $t('portDetails.quays.depthEH') }}
                        <i
                            class="fa fa-info-circle text-primary"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="$t('portDetails.info2')"
                        ></i>
                    </td>
                    <td>{{ $t('portDetails.quays.length') }}</td>
                </tr>
                <tr v-for="(quay, index) in sortedQuays" v-bind:key="index">
                    <th scope="row">{{ quay.nimetus }}</th>
                    <td>{{ quay.liik }}</td>
                    <td>{{ quay.sygavusEh2k }}</td>
                    <td>{{ quay.pikkus }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "Quays",
    props: ["portDetails"],
    computed: {
        sortedQuays() {
            return this.portDetails.quays.slice().sort((a, b) =>  a.nimetus.localeCompare(b.nimetus));
        }
    }
};
</script>

<style scoped></style>
