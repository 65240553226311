<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.portTechnicalResources.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr class="jaotus">
                    <th scope="row">{{ $t('portDetails.portTechnicalResources.technicalDeviceType') }}</th>
                    <td>{{ $t('portDetails.portTechnicalResources.name') }}</td>
                    <td>{{ $t('portDetails.portTechnicalResources.terms') }}</td>
                </tr>
                <tr
                    v-for="(portResource, index) in portDetails.portTechnicalResources"
                    v-bind:key="index"
                >
                    <th>{{ portResource.tehniliseVahendiLiik }}</th>
                    <td>{{ portResource.nimetus }}</td>
                    <td>{{ portResource.kasutustingimused }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    name: "PortTechnicalResources",
    props: ["portDetails"]
};
</script>

<style scoped></style>
