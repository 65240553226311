<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.portTechnicalData.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr>
                    <th scope="row">{{ $t('portDetails.portTechnicalData.portLocation') }}</th>
                    <td v-if="portDetails.portTechnicalData != ''"><a
                        :href="'https://www.google.com/maps/search/?api=1&query=' + northCoordinate + ',' + eastCoordinate">
                        {{ northCoordinate + " ; " + eastCoordinate }}</a></td>
                    <td v-else>-</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portTechnicalData.vesselTonnage') }}</th>
                    <td>{{ portDetails.portTechnicalData ? portDetails.portTechnicalData.veesoidukiKogumahutavus : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portTechnicalData.vesselMaxHeight') }}</th>
                    <td>{{ portDetails.portTechnicalData ? portDetails.portTechnicalData.veesoidukiMaxPikkus : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portTechnicalData.vesselMaxWidth') }}</th>
                    <td>{{ portDetails.portTechnicalData ? portDetails.portTechnicalData.veesoidukiMaxLaius : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portTechnicalData.vesselMaxDraft') }}</th>
                    <td>{{ portDetails.portTechnicalData ? portDetails.portTechnicalData.veesoidukiMaxSyvis : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portTechnicalData.entranceMinWidth') }}</th>
                    <td>{{ portDetails.portTechnicalData ? portDetails.portTechnicalData.sissesoiduteeMinLaius : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">
                        <div class="tooltip2">
                            {{ $t('portDetails.portTechnicalData.entranceMinDepthEh') }}
                            <i
                                class="fa fa-info-circle text-primary"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="$t('portDetails.info2')"
                            ></i>
                        </div>
                    </th>
                    <td>{{ portDetails.portTechnicalData ? portDetails.portTechnicalData.sissesoiduteeMinSygavusEh2k : '' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import DegreesMinutesSeconds from "@/components/portDataComponents/DegreesMinutesSeconds";
import coordinateTransformer from "@/components/portDataComponents/CoordinateTransformer";

export default {
    name: "PortTechnicalData",
    props: ["portDetails"],
    data() {
        return {
            northCoordinate: "",
            eastCoordinate: "",
        }
    },
    mounted() {
        const epsg4326Coordinates = coordinateTransformer.transformEPSG3301ToEPSG4326({
            latitude: this.portDetails.portTechnicalData.sadamaAsukoht.x,
            longitude: this.portDetails.portTechnicalData.sadamaAsukoht.y
        });

        if (epsg4326Coordinates != null) {
            this.northCoordinate = DegreesMinutesSeconds.createFromDecimalDegrees(epsg4326Coordinates.y).toFormattedString() + " N";
            this.eastCoordinate = DegreesMinutesSeconds.createFromDecimalDegrees(epsg4326Coordinates.x).toFormattedString() + " E";
        }
    }
};
</script>

<style scoped></style>
