<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.harbourMaster.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr>
                    <th scope="row">{{ $t('surname') }}</th>
                    <td>{{ portDetails && portDetails.harbourMasterData ? portDetails.harbourMasterData.sadamaKaptenPerenimi : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('firstname') }}</th>
                    <td>{{ portDetails && portDetails.harbourMasterData ? portDetails.harbourMasterData.sadamaKaptenEesnimi : '' }}</td>
                </tr>
                <tr v-if="portDetails">
                    <th scope="row">{{ $t('phone') }}</th>
                    <td v-if="!portDetails.harbourMasterData.sadamaKaptenTelefon.length">-</td>
                    <td v-else><a v-for="number in  portDetails.harbourMasterData.sadamaKaptenTelefon" v-bind:key="number.id"
                                  :href="'tel:' + number">{{ number }}</a></td>
                </tr>
                <tr v-if="portDetails">
                    <th scope="row">{{ $t('email') }}</th>
                    <td>
                        <a v-if="portDetails.harbourMasterData.sadamaKaptenEpost != '-'"
                           :href="'mailto:' + portDetails.harbourMasterData.sadamaKaptenEpost"
                           target="_top"
                        >{{ portDetails.harbourMasterData.sadamaKaptenEpost }}</a
                        >
                        <p v-else>{{ portDetails.harbourMasterData.sadamaKaptenEpost }}</p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "HarbourMasterData",
    props: ["portDetails"]
};
</script>

<style scoped></style>
