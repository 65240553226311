<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.portMainServices.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr class="jaotus">
                    <th scope="row">{{ $t('portDetails.portMainServices.serviceName') }}</th>
                    <td>
                        {{ $t('portDetails.portMainServices.providerData') }}
                    </td>
                </tr>
                <tr
                    v-for="mainData in portDetails.providedMainServiceData"
                    v-bind:key="mainData.id"
                >
                    <th scope="row">{{ mainData.serviceName }}</th>
                    <td>

                        <div v-for="(sp, index) in mainData.serviceProviders"
                             v-bind:key="index">

                            {{ sp.serviceProviderInfo }}
                            <a
                                v-if="sp.serviceProviderEmail != '-' && sp.serviceProviderEmail != ''"
                                :href="'mailto:' + sp.serviceProviderEmail"
                                target="_top"
                            >{{ sp.serviceProviderEmail }}</a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "PortMainServices",
    props: ["portDetails"]

};
</script>

<style scoped></style>
