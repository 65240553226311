<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.portWaterAreaData.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr>
                    <th scope="row">{{ $t('portDetails.portWaterAreaData.actNumber') }}</th>
                    <td>{{ portDetails.portWaterAreaData.akvatooriumOigusaktiNumber }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portWaterAreaData.actDate') }}</th>
                    <td>{{ portDetails.portWaterAreaData.akvatooriumOigusaktiKp }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portWaterAreaData.actIssuedBy') }}</th>
                    <td v-if="portDetails.portWaterAreaData.akvatooriumAktiValjaandja != null">
                        {{ portDetails.portWaterAreaData.akvatooriumAktiValjaandja }}
                    </td>
                    <td v-else>-</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portWaterAreaData.actType') }}</th>
                    <td>{{ portDetails.portWaterAreaData.akvatooriumAktiLiik }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.portWaterAreaData.actUrl') }}</th>
                    <td v-if="portDetails?.portWaterAreaData?.akvatooriumAktiLink !== ''">
                        <a
                            :href="portDetails.portWaterAreaData.akvatooriumAktiLink.startsWith('http') ? portDetails.portWaterAreaData.akvatooriumAktiLink : 'http://'+portDetails.portWaterAreaData.akvatooriumAktiLink"
                            target="_blank"
                        >{{portDetails.portWaterAreaData.akvatooriumAktiLink }}</a>
                    </td>
                    <td v-else>-</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "InstructorApp",
    props: ["portDetails"]
};
</script>

<style scoped></style>
