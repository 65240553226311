// Duplicated in App project

import proj4 from "proj4";

// Define L-EST97, must be defined manually, because proj4 does not support it initially
proj4.defs('EPSG:3301', '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

// Coordinate system based on the Earth's center of mass 
const worldGeodeticSystem = proj4.Proj('EPSG:4326');
// Coordinate system projected for Estonia's onshore and offshore
const estonianCoordinateSystem = proj4.Proj('EPSG:3301');

export class CoordinateTransformer{
    transformEPSG3301ToEPSG4326(coordinate:{latitude: number | null, longitude:number | null}): {x: number, y: number, z: number | null | undefined} | null{
        if (coordinate.latitude == null) {
            return null;
        }
        if (coordinate.longitude == null) {
            return null;
        }
        
        // Convert from EPSG:3301 to EPSG:4326
        const point = proj4.toPoint([coordinate.latitude, coordinate.longitude]);
        return proj4.transform(estonianCoordinateSystem, worldGeodeticSystem, point);        
    }
    transformEPSG4326ToEPSG3301(coordinate:{latitude: number | null, longitude:number | null}): {x: number, y: number, z: number | null | undefined} | null{
        if (coordinate.latitude  == null) {
            return null;
        }
        if (coordinate.longitude == null) {
            return null;
        }
        
        // Convert from EPSG:4326 to EPSG:3301
        const point = proj4.toPoint([coordinate.latitude, coordinate.longitude]);
        return proj4.transform(worldGeodeticSystem, estonianCoordinateSystem, point);
    }
}

const coordinateTransformer = new CoordinateTransformer();
export default coordinateTransformer;