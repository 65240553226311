<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.basicInformation.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr>
                    <th scope="row">{{ $t('portDetails.basicInformation.locode') }}</th>
                    <td>{{ portDetails.code }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.basicInformation.address') }}</th>
                    <td>{{ portDetails.address }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.basicInformation.assignment') }}</th>
                    <td>{{ portDetails.portMainData ? portDetails.portMainData.sadamaYlesanne : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">
                        {{ $t('portDetails.basicInformation.stateShips') }}
                    </th>
                    <td>
                        {{ portDetails.portMainData ? portDetails.portMainData.teenusedRiigihaldusylesannetega : '' }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">
                        {{ $t('portDetails.basicInformation.foreginShips') }}
                    </th>
                    <td>
                        {{ portDetails.portMainData ? portDetails.portMainData.teenusedValisriigiSoidukitele : '' }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.basicInformation.waterBodyType') }}</th>
                    <td>{{ portDetails.portMainData ? portDetails.portMainData.veekoguLiik : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.basicInformation.waterBodyName') }}</th>
                    <td>{{ portDetails.portMainData ? portDetails.portMainData.veekoguNimetus : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.basicInformation.navigationPeriod') }}</th>
                    <td>{{ portDetails.portMainData ? portDetails.portMainData.navigatsiooniPeriood : '' }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.basicInformation.registrationTime') }}</th>
                    <td>{{ portDetails.portMainData ? portDetails.portMainData.registrKp : '' }}</td>
                </tr>
                <tr v-if="portDetails && portDetails.portMainData && portDetails.portMainData.epost != ''">
                    <th scope="row">{{ $t('email') }}</th>
                    <td>
                        <a
                            :href="'mailto:' + portDetails.portMainData.epost"
                            target="_top"
                        >{{ portDetails && portDetails.portMainData ? portDetails.portMainData.epost : '' }}</a
                        >
                    </td>
                </tr>
                <tr v-show="portDetails && portDetails.portMainData && portDetails.portMainData.koduleht != ''">
                    <th scope="row">{{ $t('homePage') }}</th>
                    <td>
                        <a
                            :href="portDetails.portMainData.koduleht.startsWith('http') ? portDetails.portMainData.koduleht : 'http://'+portDetails.portMainData.koduleht"
                            target="_blank"
                        >{{ portDetails && portDetails.portMainData ? portDetails.portMainData.koduleht : '' }}</a
                        >
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ $t('portDetails.basicInformation.phone') }}</th>
                    <td>{{ portDetails.portMainData ? portDetails.portMainData.telefon : '' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "PortBasicInformation",
    props: ["portDetails"]
};
</script>

<style scoped></style>
