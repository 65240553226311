<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.portAdditionalServices.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr class="jaotus">
                    <th scope="row">{{ $t('portDetails.portAdditionalServices.serviceName') }}</th>
                    <td>{{ $t('portDetails.portAdditionalServices.serviceDescription') }}</td>
                </tr>
                <tr
                    v-for="additionalData in portDetails.providedAdditionalServiceData"
                    v-bind:key="additionalData.id"
                >
                    <th scope="row">
                        {{ additionalData.serviceName }}
                    </th>
                    <td>{{ additionalData.description }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "PortAdditionalServices",
    props: ["portDetails"]
};
</script>

<style scoped></style>
