<template>
    <div v-if="reloaded" class="row">
        <template v-if="portDetails.portMainData.olek =='MITTEAVALIK'">
            <div id="unactiveMessage">
                <p>{{ $t('portDetails.portNotActive') }}</p>
            </div>
        </template>
        <template v-else>
            <div class="col-md-8">
                <div class="d-flex justify-content-between">
                    <h3 class="mb-4">
                        {{ portDetails.name }}
                        <i
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="portDataChangeDate"
                        >
                        <img
                            v-if="portDetails.portMainData && portDataAgeAssessment == 'good' && contrast=='L'"
                            class="portDetailsDate"
                            src="../assets/update-date-green.svg" alt="">
                        <img
                            v-if="portDetails.portMainData && portDataAgeAssessment == 'neutral' && contrast=='L'"
                            class="portDetailsDate"
                            src="../assets/update-date-blue.svg" alt="">
                        <img
                            v-if="portDetails.portMainData && portDataAgeAssessment == 'bad' && contrast=='L'"
                            class="portDetailsDate"
                            src="../assets/update-date-red.svg" alt="">
                        <img
                            v-if="contrast=='H'"
                            class="portDetailsDate"
                            src="../assets/update-date-yellow.svg" alt="">
                        </i>
                    </h3>
                    <div>
                        <a
                            target="_blank"
                            class="btn btn-sm btn-primary"
                            :href="portAreaMapUrl"
                        ><i class="fa fa-map-pin"> </i>{{ $t('portDetails.portLocation') }}</a
                        >
                    </div>
                </div>

                <PortBasicInformation :portDetails="portDetails"/>
                
                <PortPricing v-if="portDetails && portDetails.portDocuments && portDetails.portMainData.hinnakiri != ''" :portDetails="portDetails"/>

                <PortManagerContacts :portDetails="portDetails"/>

                <HarbourMasterData :portDetails="portDetails"/>

                <PortTechnicalData :portDetails="portDetails"/>

                <PortLandAreaData :portDetails="portDetails"/>

                <PortWaterAreaData
                    :portDetails="portDetails"
                    v-if="portDetails && portDetails.portWaterAreaData"
                />

                <VesselsServed :portDetails="portDetails"/>

                <PortMainServices
                    :portDetails="portDetails"
                    v-if="portDetails && Object.keys(portDetails.providedMainServiceData).length > 0"
                />

                <PortAdditionalServices
                    :portDetails="portDetails"
                    v-if="portDetails && Object.keys(portDetails.providedAdditionalServiceData).length > 0"
                />

                <PortTechnicalResources
                    :portDetails="portDetails"
                    v-if="portDetails && Object.keys(portDetails.portTechnicalResources).length > 0"
                />

                <Quays
                    :portDetails="portDetails"
                    v-if="portDetails && Object.keys(portDetails.quays).length > 0"
                />

                <NavigationAidsMarked
                    :portDetails="portDetails"
                    v-if="portDetails && portDetails.markedNavigationAids && Object.keys(portDetails.markedNavigationAids).length > 0"
                />
            </div>
            <PortDocuments
                :portDetails="portDetails"
                v-if="portDetails 
                && (Object.keys(portDetails.portDocuments).length > 0 
                || portDetails.hasPublicRegulationAsForm
                || portDetails.hasPublicPollutionControlPlanAsForm
                || (portDetails.hasPublicWasteManagementPlanAsFormEst && getLang() === 'ee')
                || (portDetails.hasPublicWasteManagementPlanAsFormEng && getLang() === 'en'))"
            />
        </template>
    </div>
    <div v-else>
        <pulse-loader id="detailSpinner" class="spinner" :loading="loading" :color="color" :size="size"></pulse-loader>
    </div>
</template>


<script>
import PortBasicInformation from "@/components/portDataComponents/PortBasicInformation.vue";
import PortManagerContacts from "@/components/portDataComponents/PortManagerContacts.vue";
import HarbourMasterData from "@/components/portDataComponents/HarbourMasterData.vue";
import PortTechnicalData from "@/components/portDataComponents/PortTechnicalData.vue";
import PortLandAreaData from "@/components/portDataComponents/PortLandAreaData.vue";
import PortWaterAreaData from "@/components/portDataComponents/PortWaterAreaData.vue";
import VesselsServed from "@/components/portDataComponents/VesselsServed.vue";
import PortMainServices from "@/components/portDataComponents/PortMainServices.vue";
import PortAdditionalServices from "@/components/portDataComponents/PortAdditionalServices.vue";
import PortTechnicalResources from "@/components/portDataComponents/PortTechnicalResources.vue";
import Quays from "@/components/portDataComponents/Quays.vue";
import NavigationAidsMarked from "@/components/portDataComponents/NavigationAidsMarked.vue";
import PortsDataService from "../service/PortsDataService";
import PortDocuments from "@/components/portDataComponents/PortDocuments.vue";
import EventBus from "../eventBus";

import * as $ from "jquery";
import i18n from "@/i18n";
import PortPricing from "@/components/portDataComponents/PortPricing.vue";

export default {
    data() {
        return {
            portDetails: '',
            contrast: 'L',
            reloaded: false,
            loading: true,
            color: "#0D73BB",
            size: "1.5rem",
            estLang: '',
            engLang: ''
        };
    },
    name: "PortsDetails",
    components: {
        PortPricing,
        PortBasicInformation,
        PortManagerContacts,
        HarbourMasterData,
        PortTechnicalData,
        PortLandAreaData,
        PortWaterAreaData,
        VesselsServed,
        PortMainServices,
        PortAdditionalServices,
        PortTechnicalResources,
        Quays,
        NavigationAidsMarked,
        PortDocuments
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        portDataChangeDate: function () {
            return this.$i18n.t('ports.dataUpdated') + ' ' + (this.portDetails.portMainData ? this.portDetails.portMainData.muutmineKp : '-');
        },
        portDataAgeAssessment() {
            const lastChangedAtDay = this.portDetails.portMainData.muutmineKp.split('.')[0]
            const lastChangedAtMonth = this.portDetails.portMainData.muutmineKp.split('.')[1]
            const lastChangedAtYear = this.portDetails.portMainData.muutmineKp.split('.')[2]
            const lastChangedAt = new Date(lastChangedAtYear, lastChangedAtMonth - 1, lastChangedAtDay);
            const currentDate = new Date();
            const lastChangedDaysAgo = Math.round((currentDate.getTime() - lastChangedAt.getTime()) / (1000 * 3600 * 24))
            
            return lastChangedDaysAgo < 365
                ? "good"
                : lastChangedDaysAgo < 365 * 2
                    ? "neutral"
                    : "bad";
        },
        portAreaMapUrl() {
            return `${PortsDataService.gisUrl}?BBOX=${this.portDetails.bbox.map(x => Math.round(x)).join(',')}&lang=${this.getLang()}`;
        }
    },
    methods: {
        getPortDetails() {
            this.contrast = localStorage.highContrast !== undefined ? localStorage.highContrast : 'L';
            this.alertPortPhoto([]);
            this.alertFooter();
            PortsDataService.queryPort(this.id).then(response => {
                this.portDetails = response.data;
                this.alertPortPhoto(this.portDetails.photos);
                this.reloaded = true;
                this.alertFooter();

                this.$nextTick(function () {
                    $('[data-toggle="tooltip"]').tooltip({trigger: "click"})
                        .on('shown.bs.tooltip', function () {
                            setTimeout(function () {
                                $('[data-toggle="tooltip"]').tooltip('hide');
                            }, 2500);
                        });
                })
            });
        },
        alertPortPhoto(photos) {
            EventBus.$emit("PORTPHOTO", {portId: this.portDetails.id, portPhotos: photos});
        },
        alertFooter() {
            if (!this.reloaded) {
                EventBus.$emit("FOOTER", {showFooter: false});
            } else {
                EventBus.$emit("FOOTER", {showFooter: true});
            }
        },
        getLang() {
            return this.estLang == 'active' && this.engLang == '' ? "ee" : "en";
        },
    },
    mounted() {
        this.getPortDetails();
        EventBus.$on("CONTRAST", payload => {
            this.contrast = payload.contrast ? payload.contrast : 'L';
        });
    },
    watch: {
        $route: "getPortDetails"
    },
    created() {
        const lang = i18n.locale;
        this.estLang = lang == 'et' ? 'active' : '';
        this.engLang = lang == 'en' ? 'active' : '';
    }
};
</script>
<style scoped></style>
