// Duplicated in App project

export default class DegreesMinutesSeconds{
    degrees: string | null;
    minutes: string | null;
    seconds: string | null;
    
    constructor(degrees: string | null, minutes: string | null, seconds: string | null) {
        this.degrees = degrees;
        this.minutes = minutes;
        this.seconds = seconds;
    }
    
    static createFromDecimalDegrees(decimalDegrees: number | null | undefined): DegreesMinutesSeconds | null
    {
        if (decimalDegrees == null) 
        {
            return null;
        }

        const degrees = Math.floor(decimalDegrees);
        const minutesNotTruncated = (decimalDegrees - degrees) * 60;
        const minutes = Math.floor(minutesNotTruncated);
        const seconds = Math.round(((minutesNotTruncated - minutes) * 60) * 1000) / 1000;

        return new DegreesMinutesSeconds(`${degrees.toString()}`, `${minutes.toString()}`, `${seconds.toString()}`)
    }
    
    equals(other: DegreesMinutesSeconds | null)
    {
        return other != null && this.degrees == other.degrees && this.minutes == other.minutes && this.seconds == other.seconds;
    }
    
    toFormattedString(){
        return  `${this.degrees}° ${this.minutes}' ${this.seconds}"` ;
    }
}