<template>
    <div class="col">
        <h3 class="heading">{{ $t('portDetails.portDocuments.title') }}</h3>
        <ul>
            <li v-if="portDetails.hasPublicRegulationAsForm">
                <a class="no-href-link" @click="downloadRegulationAsPdf(portDetails.id)" target="_blank">{{ $t('portDetails.portDocuments.regulation') }}</a>
            </li>
            <li v-if="portDetails.hasPublicPollutionControlPlanAsForm && inEstonian">
                <a :href="`${portDetails.publicPollutionControlPlanUrl}?inEstonian=${inEstonian}`" target="_blank">{{ $t('portDetails.portDocuments.pollutionControlPlan') }}</a>
            </li>
            <li v-if="(portDetails.hasPublicWasteManagementPlanAsFormEst && inEstonian) || (portDetails.hasPublicWasteManagementPlanAsFormEng && !inEstonian)">
                <a :href="`${portDetails.publicWasteManagementPlanAUrl}?inEstonian=${inEstonian}`" target="_blank">{{ $t('portDetails.portDocuments.wasteManagementPlan') }}</a>
            </li>
            <li v-for="portDocument in portDetails.portDocuments"
                v-bind:key="portDocument.id" 
            >
                <div class="no-href-link">
                    <a
                        @click="downloadFile(portDetails.id, portDocument.id)"
                        target="_blank"
                    >{{ portDocument.displayName ?? portDocument.name }}</a>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import PortsDataService from "../../service/PortsDataService";
import EventBus from "../../eventBus";
import i18n from "@/i18n";

export default {
    name: "PortDocuments",
    data() {
        return {
            inEstonian: true,
        };
    },
    props: ["portDetails"],
    methods: {
        // Keep this method synchronous to avoid popup blocking in firefox
        downloadFile(portId, fileId) {
            const downloadWindow = window.open();
            PortsDataService.queryFileDownloadLink(portId, fileId)
                .then(response => {
                    downloadWindow.location.href = response.data; // Start file download in a new tab
                    window.setTimeout(function () {
                        downloadWindow.close();
                    }, 1000);
                })
                .catch(error => {
                    EventBus.$emit("FLASHMESSAGE", {message: this.$i18n.t('portMessage.documentOpeningFail'), error: true});
                })
        },
      downloadRegulationAsPdf(portId){
            PortsDataService.portRegulationAsPdf(portId, this.inEstonian)
                .then(result => {
                  const fileUrl = window.URL.createObjectURL(new Blob([result.data], {type: 'application/pdf'}));
                  window.open(fileUrl, '_blank');
                  window.URL.revokeObjectURL(fileUrl);
            }).catch(e => {
              throw e;
            });
        }
    },
    created() {
        this.inEstonian = i18n.locale === 'et';
    }
};
</script>

<style scoped></style>
