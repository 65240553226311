<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.portManager.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr v-show="portDetails && portDetails.portManagerContacts && portDetails.portManagerContacts.sadamaPidajaArinimiPerenimi != ''">
                    <th scope="row">{{ $t('portDetails.portManager.surname') }}</th>
                    <td>{{ portDetails && portDetails.portManagerContacts ? portDetails.portManagerContacts.sadamaPidajaArinimiPerenimi : '' }}</td>
                </tr>
                <tr v-show="portDetails && portDetails.portManagerContacts && portDetails.portManagerContacts.sadamaPidajaEesnimi != ''">
                    <th scope="row">{{ $t('firstname') }}</th>
                    <td>{{ portDetails && portDetails.portManagerContacts ? portDetails.portManagerContacts.sadamaPidajaEesnimi : '' }}</td>
                </tr>
                <tr v-show="portDetails && portDetails.portManagerContacts && portDetails.portManagerContacts.sadamaPidajaRegKood !=''">
                    <th scope="row">{{ $t('portDetails.portManager.registryCode') }}</th>
                    <td>{{ portDetails && portDetails.portManagerContacts ? portDetails.portManagerContacts.sadamaPidajaRegKood : '' }}</td>
                </tr>
                <tr v-if="portDetails">
                    <th scope="row">{{ $t('phone') }}</th>
                    <td v-if="!portDetails.portManagerContacts.sadamaPidajaTelefon || !portDetails.portManagerContacts.sadamaPidajaTelefon.length">-</td>
                    <td v-else><a v-for="number in  portDetails.portManagerContacts.sadamaPidajaTelefon" v-bind:key="number.id"
                                  :href="'tel:' + number">{{ number }}</a></td>
                </tr>
                <tr v-if="portDetails && portDetails.portManagerContacts && portDetails.portManagerContacts.sadamaPidajaEpost != ''">
                    <th scope="row">{{ $t('email') }}</th>
                    <td>
                        <a
                            :href="'mailto:' + portDetails.portManagerContacts.sadamaPidajaEpost"
                            target="_top"
                        >{{ portDetails && portDetails.portManagerContacts ? portDetails.portManagerContacts.sadamaPidajaEpost : '' }}</a
                        >
                    </td>
                </tr>
                <tr v-show="portDetails && portDetails.portManagerContacts && portDetails.portManagerContacts.sadamaPidajaKoduleht != ''">
                    <th scope="row">{{ $t('homePage') }}</th>
                    <td>
                        <a
                            :href="portDetails.portManagerContacts.sadamaPidajaKoduleht.startsWith('http') ? portDetails.portManagerContacts.sadamaPidajaKoduleht : 'http://' + portDetails.portManagerContacts.sadamaPidajaKoduleht"
                            target="_blank"
                        >{{ portDetails && portDetails.portManagerContacts ? portDetails.portManagerContacts.sadamaPidajaKoduleht : '' }}</a
                        >
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "PortManagerContacts",
    props: ["portDetails"]
};
</script>

<style scoped></style>
