<template>
    <div class="port-block">
        <h3 class="heading port-block-title">{{ $t('portDetails.servedVessels.title') }}</h3>
        <div class="table-responsive">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr>
                    <th scope="row">{{ $t('portDetails.servedVessels.vesselType') }}</th>
                    <th scope="row">{{ $t('portDetails.servedVessels.vesselConstraint') }}</th>
                </tr>
                <tr v-for="(item, index) in portDetails.vesselsServed" :key="index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.constraints }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "VesselsServed",
    props: ["portDetails"]
};
</script>

<style scoped></style>
