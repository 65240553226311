import { render, staticRenderFns } from "./VesselsServed.vue?vue&type=template&id=d45b2970&scoped=true&"
import script from "./VesselsServed.vue?vue&type=script&lang=js&"
export * from "./VesselsServed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d45b2970",
  null
  
)

export default component.exports