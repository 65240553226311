import { render, staticRenderFns } from "./PortAdditionalServices.vue?vue&type=template&id=647019b8&scoped=true&"
import script from "./PortAdditionalServices.vue?vue&type=script&lang=js&"
export * from "./PortAdditionalServices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647019b8",
  null
  
)

export default component.exports