<template>
    <div class="port-block">
        <h5 class="port-block-title" @click="toggleAllDetails()">{{ $t('portDetails.navigationMarked.title') }}
            <i :class="`fa fa-chevron-circle-${ areAllDetailsOpen() ? 'up' : 'down'} text-primary pr-1`" aria-hidden="true"></i>
        </h5>
        <div class="">
            <table class="table table-bordered table-sm">
                <tbody>
                <tr class="jaotus">
                    <th scope="row">{{ $t('portDetails.navigationMarked.number') }}</th>
                    <td>{{ $t('portDetails.navigationMarked.name') }}</td>
                </tr>
                <template v-for="navigationAid in portDetails.markedNavigationAids">
                    <tr
                        class="bg-light"
                        @click="toggleDetails(navigationAid.id)"
                        :key="navigationAid.id + 'header'"
                        style="cursor: pointer"
                    >
                        <th>
                            <i
                                v-if="!showInfo[navigationAid.id]"
                                class="fa fa-chevron-circle-down text-primary pr-2"
                            ></i>
                            <i
                                v-if="showInfo[navigationAid.id]"
                                class="fa fa-chevron-circle-up text-primary pr-2"
                            ></i>
                            {{ navigationAid.margiNumber }}
                        </th>
                        <td>{{ navigationAid.nimetus }}</td>
                    </tr>
                    <tr v-if="showInfo[navigationAid.id]" :key="navigationAid.id">
                        <td colspan="2">
                            <div class="row" style="font-size: 0.85rem">
                                <div class="col-md-4">
                                    <div>
                                        <strong>{{ $t('portDetails.navigationMarked.navNumber') }}</strong
                                        >{{ navigationAid.margiNumber }}
                                    </div>
                                    <div>
                                        <strong>{{ $t('portDetails.navigationMarked.navName') }}</strong>{{ navigationAid.nimetus }}
                                    </div>
                                    <div>
                                        <strong>{{ $t('portDetails.navigationMarked.navTp') }}</strong>{{ navigationAid.margiLiik }}
                                    </div>
                                    <div>
                                        <strong>{{ $t('portDetails.navigationMarked.navSeason') }}</strong>{{ navigationAid.sessoonsus }}
                                    </div>
                                    <div>
                                        <strong>{{ $t('portDetails.navigationMarked.navStatus') }}</strong> {{ navigationAid.olek }}
                                    </div>
                                    <div>
                                        <strong>{{ $t('portDetails.navigationMarked.navClass') }}</strong> {{ navigationAid.klass }}
                                    </div>
                                    <div>
                                        <strong>{{ $t('portDetails.navigationMarked.navType') }}</strong> {{ navigationAid.tyyp }}
                                    </div>
                                    <div v-if="navigationAid.asukoht != null">
                                        <strong>{{ $t('portDetails.navigationMarked.navLockN') }}</strong>
                                        {{ getCoordinatesDescription(navigationAid.asukoht).north }}
                                    </div>
                                    <div v-if="navigationAid.asukoht != null">
                                        <strong>{{ $t('portDetails.navigationMarked.navLockE') }}</strong>
                                        {{ getCoordinatesDescription(navigationAid.asukoht).east }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div v-if="navigationAid.hooldaja != null">
                                        <strong>{{ $t('portDetails.navigationMarked.maintainerName') }}</strong>
                                        {{ navigationAid.hooldaja }}
                                    </div>
                                    <div v-if="navigationAid.hooldajaKontakt != null">
                                        <strong>{{ $t('portDetails.navigationMarked.maintainerContact') }}</strong>
                                        {{ navigationAid.hooldajaKontakt }}
                                    </div>
                                    <div v-if="navigationAid.radariPeegeldi != null">
                                        <strong>{{ $t('portDetails.navigationMarked.radarRefl') }}</strong>
                                        {{ navigationAid.radariPeegeldi }}
                                    </div>
                                    <div v-if="navigationAid.topimark != null">
                                        <strong>{{ $t('portDetails.navigationMarked.topMark') }}</strong>{{ navigationAid.topimark }}
                                    </div>
                                    <div v-if="navigationAid.margiAsukohaKorgus != null">
                                        <strong>{{ $t('portDetails.navigationMarked.baseHeight') }}</strong>
                                        {{ navigationAid.margiAsukohaKorgus }}
                                    </div>
                                    <div v-if="navigationAid.margiKirjeldus != null">
                                        <strong>{{ $t('portDetails.navigationMarked.description') }}</strong>
                                        {{ navigationAid.margiKirjeldus }}
                                    </div>
                                    <div v-if="navigationAid.margiKorgusMaapinnast != null">
                                        <strong>{{ $t('portDetails.navigationMarked.relHeight') }}</strong>
                                        {{ navigationAid.margiKorgusMaapinnast }}
                                    </div>
                                    <div v-if="navigationAid.tuleIseloom != null">
                                        <strong>{{ $t('portDetails.navigationMarked.lightChar') }}</strong
                                        >{{ navigationAid.tuleIseloom }}
                                    </div>
                                    <div v-if="navigationAid.tuleTsykliKirjeldus != null">
                                        <strong>{{ $t('portDetails.navigationMarked.lightDesc') }}</strong>
                                        {{ navigationAid.tuleTsykliKirjeldus }}
                                    </div>
                                    <div v-if="navigationAid.tuleVarvitoonid != null">
                                        <strong>{{ $t('portDetails.navigationMarked.light') }}</strong> {{ navigationAid.tuleVarvitoonid }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                        <strong>{{ $t('portDetails.navigationMarked.leadingLines') }}</strong>
                                        {{ navigationAid.kuuluvusSihiKooseisu }}
                                    </div>
                                    <div v-if="navigationAid.sihtiKuuluvaTeiseMargiNr != null">
                                        <strong>{{ $t('portDetails.navigationMarked.otherNumber') }}</strong
                                        >{{ navigationAid.sihtiKuuluvaTeiseMargiNr }}
                                    </div>
                                    <div v-if="navigationAid.sihtiKuuluvaTeiseMargiNimi != null">
                                        <strong>{{ $t('portDetails.navigationMarked.otherName') }}</strong
                                        >{{ navigationAid.sihtiKuuluvaTeiseMargiNimi }}
                                    </div>
                                    <div v-if="navigationAid.sihiPeiling != null">
                                        <strong>{{ $t('portDetails.navigationMarked.peiling') }}</strong
                                        >{{ navigationAid.sihiPeiling }}
                                    </div>
                                    <div v-if="navigationAid.tuleKorgusMerepinnast != null">
                                        <strong>{{ $t('portDetails.navigationMarked.lightElevation') }}</strong
                                        >{{ navigationAid.tuleKorgusMerepinnast }}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import DegreesMinutesSeconds from "@/components/portDataComponents/DegreesMinutesSeconds";
import coordinateTransformer from "@/components/portDataComponents/CoordinateTransformer";
export default {
    data() {
        return {
            showInfo: {},
            northCoordinate: "",
            eastCoordinate: ""
        };
    },
    methods: {
        areAllDetailsOpen() {
            return Object.values(this.showInfo).every(value => value === true);
        },
        addInfoRows() {
            this.portDetails.markedNavigationAids.forEach(element => { this.showInfo[element.id] = false; });
        },
        toggleDetails(id) {
            this.showInfo[id] = this.showInfo[id] === false;
            this.$forceUpdate();
        },
        toggleAllDetails() {
            if (this.areAllDetailsOpen()) {
                Object.keys(this.showInfo).forEach(key => { this.showInfo[key] = false; });
            } else {
                Object.keys(this.showInfo).forEach(key => { this.showInfo[key] = true; });
            }

            this.$forceUpdate();
        },
        beforePrint: function () {
            this.addInfoRows();
        },
        getCoordinatesDescription(location) {
            const epsg4326Coordinates = coordinateTransformer.transformEPSG3301ToEPSG4326({
                latitude: location.x,
                longitude: location.y
            });

            return {
                north: DegreesMinutesSeconds.createFromDecimalDegrees(epsg4326Coordinates.y).toFormattedString() + " N",
                east: DegreesMinutesSeconds.createFromDecimalDegrees(epsg4326Coordinates.x).toFormattedString() + " E"
            }
        }
    },
    created: function () {
        window.addEventListener('beforeprint', this.beforePrint);
        this.addInfoRows();
    },
    name: "NavigationAidsMarked",
    props: ["portDetails"],
};
</script>

<style scoped>
.blokk {
    display: table;
}
</style>
